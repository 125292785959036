import React, { useState } from 'react';
// import Parser from 'react-html-parser';

const insurances = ['Independence', 'Aetna', 'United Health Care'];
const insuranceOptions = [
    { value: 'Independence', label: 'Independence' },
    { value: 'Aetna', label: 'Aetna' },
    { value: 'United Health Care', label: 'United Health Care' },
  ];
const InputForm = () => {
  // State to store the form data
  const [formData, setFormData] = useState({
    insurance_name: '',
    medication_name: '',
    // message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const [insuranceInput, setInsuranceInput] = useState('');
  const [filteredInsurances, setFilteredInsurances] = useState([]);



  const [submitted, setSubmitted] = useState(false);
  const [alternatives, setAlternatives] = useState([]);

  
  const handleInsuranceChange = (event) => {
    const input = event.target.value;
    setInsuranceInput(input);
    if (input.length > 1) {
        //Filter for matching insurances
        const matches = insurances.filter(insurance => 
            insurance.toLowerCase().includes(input.toLowerCase())
            );
        setFilteredInsurances(matches);
    } else {
        setFilteredInsurances([]);
    }

  };

  const selectInsurance = (name) => {
    setInsuranceInput(name);
    setFilteredInsurances([]);
  }

  // Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitted(true);
    // You would typically also reset the form or provide user feedback here
    // Here, formData is the state that holds your form inputs
    const formData = {
        insurance_name: document.getElementById('insurance_name').value,
        medication_name: document.getElementById('medication_name').value
    };
    // Submit the form data, for example, to an API endpoint
    console.log("handleSubmit():");
    console.log(formData);
    

    try {
        // Send a POST request to your Cloudflare Worker endpoint
        const response = await fetch('https://my-form-handler.nspnagesh.workers.dev', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json(); // parse JSON from the response
            })
            .then(data => {
                console.log('Success:', data);
                if (data && data.data && Array.isArray(data.data.alternatives)) {
                    setAlternatives(data.data.alternatives);
                } else {
                    setAlternatives([]); // Ensure alternatives is always an array
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
                // handle your error state here
            });

        // Here, handle the successful submission, e.g., showing a success message
        // Reset the form if needed
        // document.getElementById('yourFormId').reset();
    } catch (error) {
        console.error('Error:', error);
        // Here, handle the error, e.g., showing an error message
        setResponseMessage('An error occurred. Please try again later.');

    }
  };

  return (
    <main> 
        <form onSubmit={handleSubmit}>
            <div> 
                <label htmlFor="insurance_name">Insurance:</label>
                <select 
                    // type="text"
                    id="insurance_name"
                    name="insurance_name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={"Insurance Name"}
                > 
                    {insuranceOptions.map((option) => (
                      <option key = {option.value} value={option.value}>
                        {option.label}
                      </option>  
                    ))}
                </select>
                
                {/* <input
                    type="text"
                    id="insurance_name"
                    name="insurance_name"
                    value={formData.name}
                    onChange={handleInsuranceChange}
                    placeholder={"Insurance Name"}
                />
                <div>
                    {filteredInsurances.map((name) => (
                        <div key = {name} onClick={() => selectInsurance(name)}>
                            {name}  
                        </div>
                     ))}
                </div> */}
            </div>
            <div>

            </div>
            <div>
                <label htmlFor="medication_name">Medication:</label>
                <input
                    type="text"
                    id="medication_name"
                    name="medication_name"
                    placeholder={"Medication Name"}
                    value={formData.medication_name}
                    onChange={handleChange}
                />
            </div>

            {/* <label htmlFor="message">Message:</label>
            <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
            /> */}
            <div>
                <button type="submit">Submit</button>
            </div>
            {/* dangerouslySetInnerHTML={{ __html:  */}
            {/* {responseMessage && <div>{Parser(responseMessage)}</div>} */}
            {/* {responseMessage && (<div dangerouslySetInnerHTML={{ __html: responseMessage }} />)} */}

        </form>
        {submitted && (
            <section>
                <h2>Found medication "{formData.medication_name}"</h2>
                <p>This medication is not preferred by the insurance.</p>
                <p>Searching for alternatives...</p>
                {/* You would replace this list with the actual alternatives fetched */}
                <ul>
                    {alternatives.map((alt, index) => (
                    <li key={index}>{alt}</li>
                    ))}
                </ul>
            </section>
        )};
    </main>
      

  );
};

export default InputForm;
